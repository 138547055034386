:root {
  --modal-sm-h: 170px;
  --modal-sm-w: 400px;
  --modal-md-h: 370px;
  --modal-lg-h: 310px;
  --modal-lg-w: 680px;
  --modal-xlg-h: 370px;
  --modal-xxlg-h: 370px;
  --modal-xxlg-w: 750px;

  --timeline-sections-w: 288px;
  --timeline-conten-gap: 30px;

  --shadows-0: none;
  --shadows-1: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.45);
  --shadows-2: inset 0px 1px 6px rgba(0, 0, 0, 0.3);
  --shadows-3: 0px 0.5px 3px 0px rgba(0, 0, 0, 0.45);
  --shadows-4: 0px 0.5px 2px rgba(0, 0, 0, 0.35);
  --shadows-5: 0 0.5px 4px 0 rgba(0, 0, 0, 0.1);
  --shadows-6: 0px 2px 6px rgba(0, 0, 0, 0.1);
  --shadows-7: 0px 1px 6px rgba(0, 0, 0, 0.3);
  --shadows-8: 0px 2px 6px rgba(0, 0, 0, 0.3);

  --clr-mountainMeadow: #1db954;
  --clr-wildSand: #f4f4f4;
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-emperor: #505050;
  --clr-balticSea: #2a292d;
  --clr-balticSeaDark: #19181f;
  --clr-shipGray: #3e3b43;
  --clr-dustyGray: #999999;
  --clr-silver: #cacaca;
  --clr-gray: #808080;
  --clr-shalimar: #fffbb9;
  --clr-lisbonBrown: #3f3d1e;
  --clr-pomegranate: #f03a2e;
  --clr-carnation: #f36158;
  --clr-yellow: #fcf403;
  --clr-oldGold: #d4af37;
  --clr-malachite: #1ed760;
  --clr-malachiteLight: #1bd317;
  --clr-malachiteLighty: #bbf2ba;
  --clr-fetaLight: #effcef;
  --clr-frenchPass: #badcfd;
  --clr-corn: #e3da02;
  --clr-california: #f39604;
  --clr-kashmirBlue: #475993;
  --clr-texasRose: #ffc250;
  --clr-sunsetOrange: #fd5341;
  --clr-mediumRedViolet: #cb39a4;
  --clr-scarpaFlow: #56535e;
  --clr-feta: #e9fbe8;

  --warning-main: var(--clr-oldGold);
  --error-main: var(--clr-pomegranate);
  --error-light: var(--clr-carnation);
  --success-main: var(--clr-malachiteLight);
  --success-light: var(--clr-malachiteLighty);
  --info-main: var(--clr-yellow);
  --action-disabled: var(--clr-frenchPass);
  --progress-low: var(--clr-carnation);
  --progress-middle: var(--clr-yellow);
  --progress-full: var(--clr-malachiteLight);
}

body[theme='light'] {
  --clr-toryBlue: hsl(var(--light-hue) 79% 32%);
  --clr-dodgerBlueLight: hsl(var(--light-hue) 100% 70%);
  --clr-selago: hsl(var(--light-hue) 83% 98%);
  --clr-tropicalBlue: hsl(var(--light-hue) 79% 85%);
  --clr-hawkesBlue: hsl(var(--light-hue) 83% 95%);
  --action-hover: var(--clr-toryBlue);

  /* GENERAL */
  --selection-hover: var(--clr-hawkesBlue);
  --selection-iconsHover: var(--clr-tropicalBlue);
  --selection-menuHover: var(--light-color);
  --selection-filterHover: var(--clr-hawkesBlue);
  --menuItemSelectedBg: var(--clr-hawkesBlue);
  --menuItemHoverBg: var(--clr-toryBlue);
  --whiteToBalticSeaDark: var(--clr-white);
  --whiteToBlack: var(--clr-white);
  --sectionHover: var(--clr-hawkesBlue);
  --whiteToEmperor: var(--clr-white);
  --primaryLightToBalticSeaDark: var(--primary-light);
  --primaryLightToBalticSea: var(--primary-light);
  --whiteToShipGray: var(--clr-white);
  --successLightToShipGray: var(--success-light);
  --primaryLightToShipGray: var(--primary-light);
  --primaryLightToGray: var(--primary-light);
  --transparentToWhite: transparent;
  --balticSeaToWhite: var(--clr-balticSea);
  --buttonActiveToWhite: var(--clr-toryBlue);
  --selagoToScarpaFlow: var(--clr-selago);
  --primaryLightToScarpaFlow: var(--primary-light);
  --fetaToBalticSeaDark: var(--clr-feta);
  --dustGrayToSilver: var(--clr-dustyGray);
  --primaryLightToPrimaryMain: var(--primary-light);
  --primaryMainToPrimaryLight: var(--primary-main);
  --vibo: #1a7ee3;
  --must-play-main: var(--clr-shalimar);
  --toryBlueToPrimaryLight: var(--clr-toryBlue);
  --emperorToWhite: var(--clr-emperor);
  --primaryLightToTropicalBlue: var(--primary-light);
  --fetaLightToShipGray: var(--clr-fetaLight);
  --dustyGrayToEmperor: var(--clr-dustyGray);

  /* PRIMARY */
  --primary-main: var(--light-color);
  --primary-light: var(--clr-tropicalBlue);
  --primary-lighty: var(--clr-hawkesBlue);
  --primary-contrastText: var(--clr-white);

  /* TEXT */
  --text-primary: var(--clr-black);
  --text-secondary: var(--clr-emperor);
  --text-hint: var(--clr-dustyGray);
  --text-disabled: var(--clr-silver);

  /* BACKGROUND */
  --background-default: var(--clr-selago);
  --background-paper: var(--clr-white);
  --background-photo: var(--clr-tropicalBlue);
  --background-dropDown: var(--clr-white);
  --background-input: var(--clr-selago);

  /* BUTTON */
  --button-disabled: var(--clr-tropicalBlue);
  --button-active: var(--clr-toryBlue);

  /* OVERLAY */
  --overlay-default: 'rgba(255, 255, 255, 0.7)';
  --overlay-skeleton: 'linear-gradient(90deg, #ddd 0%, #eee 100%)';
}

body[theme='dark'] {
  --clr-toryBlue: hsl(var(--dark-hue) 79% 32%);
  --clr-dodgerBlueLight: hsl(var(--dark-hue) 100% 70%);
  --clr-selago: hsl(var(--dark-hue) 83% 98%);
  --clr-tropicalBlue: hsl(var(--dark-hue) 79% 85%);
  --clr-hawkesBlue: hsl(var(--dark-hue) 83% 95%);
  --action-hover: var(--clr-toryBlue);

  /* GENERAL */
  --selection-hover: var(--clr-shipGray);
  --selection-iconsHover: var(--clr-balticSeaDark);
  --selection-menuHover: var(--clr-dodgerBlueLight);
  --selection-filterHover: var(--clr-emperor);
  --menuItemSelectedBg: var(--clr-toryBlue);
  --menuItemHoverBg: var(--clr-dodgerBlueLight);
  --whiteToBalticSeaDark: var(--clr-balticSeaDark);
  --whiteToBlack: var(--clr-black);
  --sectionHover: var(--clr-shipGray);
  --whiteToEmperor: var(--clr-emperor);
  --primaryLightToBalticSeaDark: var(--clr-balticSeaDark);
  --primaryLightToBalticSea: var(--clr-balticSea);
  --whiteToShipGray: var(--clr-shipGray);
  --successLightToShipGray: var(--clr-shipGray);
  --primaryLightToShipGray: var(--clr-shipGray);
  --primaryLightToGray: var(--clr-gray);
  --transparentToWhite: var(--clr-white);
  --balticSeaToWhite: var(--clr-white);
  --buttonActiveToWhite: var(--clr-white);
  --selagoToScarpaFlow: var(--clr-scarpaFlow);
  --primaryLightToScarpaFlow: var(--clr-scarpaFlow);
  --fetaToBalticSeaDark: var(--clr-balticSeaDark);
  --dustGrayToSilver: var(--clr-silver);
  --primaryLightToPrimaryMain: var(--primary-main);
  --primaryMainToPrimaryLight: var(--primary-light);
  --vibo: #2d8ff1;
  --must-play-main: var(--clr-lisbonBrown);
  --toryBlueToPrimaryLight: var(--primary-light);
  --emperorToWhite: var(--clr-white);
  --primaryLightToTropicalBlue: var(--clr-tropicalBlue);
  --fetaLightToShipGray: var(--clr-shipGray);
  --dustyGrayToEmperor: var(--clr-emperor);

  /* PRIMARY */
  --primary-main: var(--dark-color);
  --primary-light: var(--clr-dodgerBlueLight);
  --primary-lighty: var(--clr-shipGray);
  --primary-contrastText: var(--clr-white);

  /* TEXT */
  --text-primary: var(--clr-white);
  --text-secondary: var(--clr-white);
  --text-hint: var(--clr-gray);
  --text-disabled: var(--clr-silver);

  /* BACKGROUND */
  --background-default: var(--clr-balticSeaDark);
  --background-paper: var(--clr-balticSea);
  --background-photo: var(--clr-shipGray);
  --background-dropDown: var(--clr-balticSeaDark);
  --background-input: var(--clr-shipGray);

  /* BUTTON */
  --button-disabled: var(--clr-toryBlue);
  --button-active: var(--clr-dodgerBlueLight);

  /* OVERLAY */
  --overlay-default: 'rgba(42, 41, 45, 0.7)';
  --overlay-skeleton: 'linear-gradient(90deg, rgba(42,41,45,1) 0%, rgba(62,59,67,1) 100%)';
}

@media screen and (min-width: 1441px) {
  :root {
    --modal-sm-h: 220px;
    --modal-sm-w: 560px;
    --modal-md-h: 524px;
    --modal-lg-h: 364px;
    --modal-lg-w: 800px;
    --modal-xlg-h: 564px;
    --modal-xxlg-h: 600px;
    --modal-xxlg-w: 1000px;

    --timeline-sections-w: 384px;
    --timeline-conten-gap: 56px;
  }
}
